/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, Headline, Obl, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Acral lentiginous melanoma (ALM) is ", React.createElement(_components.strong, null, "the most common type of melanoma in people with darker skin, and is not associated with sun exposure.")), React.createElement(_components.p, null, "It may appear as a black or brown area in hard-to-spot places such as nails and on the soles of the feet or palms of the hands."), React.createElement(_components.p, null, "Acral lentiginous melanoma is a type of skin cancer that occurs when pigment cells, or melanocytes, become cancerous.")), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Did You Know?"), React.createElement(_components.p, null, "Bob Marley was diagnosed with acral lentiginous melanoma which ultimately claimed his life in 1981 at age 36."), React.createElement(_components.p, null, "When a dark spot appeared under his toenail, Marley attributed it to a soccer injury. Eventually he was diagnosed with the disease but was not treated. His melanoma spread to other areas of his body and tragically cut his life short.")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    height: 1,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "ALM that arises on the nail bed is called ", React.createElement(_components.strong, null, "subungual melanoma"), ". It can cause bumpiness and discoloration that extends into the cuticle and skin around the nail."), React.createElement(_components.p, null, "It can also cause the nail to break or crack as it progresses. Though small brown streaks on the nail can be normal, you should see a doctor if the streak is very irregular.")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "ALM can also occur on the palms and feet.")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure04",
    image: "figure04",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure04",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "As the tumor gets worse, the spot may become bumpier and rougher to the touch.")), React.createElement(Pin, {
    id: "figure04",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "video01",
    video: "jXq97PyAl7w",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "video01",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hear Dr. Chesahna Kindred of the ", React.createElement(Obl, {
    to: "https://kindredhairandskin.com/",
    title: "Dr. Kindred's Practice"
  }, "Kindred Hair and Skin Center"), " share more how you you can watch out for Melanoma.")), React.createElement(Pin, {
    id: "video01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "Treatments"), React.createElement(_components.p, null, "ALM can be treated if caught early enough but it can be hard to spot. It usually presents as an irregular dark spot or stain surrounded by normal skin. Even if the spot is small there may be a lot more you can not see below the surface-damaging cells."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Practice routine skin checks in hard-to-spot places such as nails and on the soles of the feet or palms of the hands to recognize ALM early and prevent progression."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "If you spot anything suspicious, contact a dermatologist immediately."), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
